jQuery(document).ready(function ($) {

  UTM = {
    debug: true,
  };

  /**
   * Set given cookie.
   *
   * @param {string} cname
   *   Cookie Name.
   * @param {string|int} cvalue
   *   Cookie value.
   * @param {int} [exhours]
   *   Cookie duration (in hours).
   *
   * @return {undefined}
   */
  UTM.setCookie = function(cname, cvalue, exhours) {
    const d = new Date();
    if (typeof exhours === 'undefined') {
      exhours = Drupal.ToolsHelper.COOKIE_DEFAULT_DURATION;  // Duration in hours
    }
    d.setTime(d.getTime() + (exhours * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  };

  /**
   * Set a cookie value, or null if not defined.
   *
   * @param {string} cname
   *   Cookie Name.
   *
   * @returns {string|null}
   *   Cookie value of found, otherwise null.
   */
  UTM.getCookie = function (cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  };

  /**
   * Parse the URL to extract parameters.
   *
   * @param {string} name
   * @return {string}
   */
  UTM.getParameterByName = function (name) {
    name = name.replace(/[\[]/, "\[").replace(/[\]]/, "\]");
    var regex = new RegExp("[\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results !== null ? decodeURIComponent(results[1].replace(/\+/g, " ")) : '';
  };

  /**
   * Set UTM function
   *
   * Assumes all hidden fields follow this format :
   * <input name="utm_term" type="hidden" />
   *
   * @param {string} utm_param
   * @param {*} utm_val
   */
  UTM.set_utm = function (utm_param, utm_val) {
    if (UTM.debug) {
      console.log('UTM - Managing utm logic for "' + utm_param + '" with param "' + utm_val + '"');
    }

    if (utm_val === null) {
      return;
    }

    var utmCookie = UTM.getCookie('utm_' + utm_param);
    if (utmCookie!== null && TrackingConsentManager.canTrackCurrentUser()) {
      if (UTM.debug) {
        console.log('UTM - Set input to ' + utmCookie);
      }
      $('input[name="utm_' + utm_param + '__c"]').val(utmCookie).change();
    }
    else {
      if (UTM.debug) {
        console.log('UTM - Using current code / no code set');
      }
      if (utm_val !== '') {
        if (UTM.debug) {
          console.log('UTM - Set the cookie');
        }
        UTM.setCookie('utm_' + utm_param , utm_val, {path: '/'});
      }
    }
  };

  // Do the Set UTM function for each UTM.
  for (var utm of ['source', 'medium', 'campaign', 'content', 'term']) {
    UTM.set_utm(utm, UTM.getParameterByName('utm_' + utm));
  }

});
